import { AvatarFallback, AvatarRoot } from '../components'
import { UserItem } from '../types'
import { AvatarStack } from './Avatar'
import { UserAvatar, UserAvatarProps } from './UserAvatar'

type Props = {
  users: UserItem[]
  size?: UserAvatarProps['size']
  className?: string
}

export const UsersAvatars = ({ users, className, size = 'md' }: Props) => {
  return users?.length ? (
    <AvatarStack className={className}>
      {users.slice(0, 3).map((user) => (
        <UserAvatar key={user.id} user={user} size={size} />
      ))}
      {users.length > 3 && (
        <AvatarRoot className='inline-flex h-8 w-8 items-center justify-center overflow-hidden'>
          <AvatarFallback className='bg-border text-foreground flex h-full w-full items-center justify-center rounded-full font-medium uppercase'>
            +{users.length - 3}
          </AvatarFallback>
        </AvatarRoot>
      )}
    </AvatarStack>
  ) : null
}
