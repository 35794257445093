import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cva, VariantProps } from 'class-variance-authority'
import { Check } from 'lucide-react'
import * as React from 'react'
import { cn } from '../utils'

const variants = cva(
  [
    'ring-offset-background',
    'focus-visible:ring-ring',
    'data-[state=checked]:bg-primary',
    'data-[state=checked]:text-primary-foreground',
    'peer',
    'h-4',
    'w-4',
    'shrink-0',
    'rounded-sm',
    'border',
    'focus-visible:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-offset-2',
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        default: 'border-primary',
        secondary: 'border-slate-700 data-[state=checked]:border-primary',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

type Props = VariantProps<typeof variants> & React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, Props>(
  ({ className, variant, ...props }, ref) => (
    <CheckboxPrimitive.Root ref={ref} className={cn(variants({ variant }), className)} {...props}>
      <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
        <Check className='h-4 w-4' />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  ),
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
