import { Fallback, Image, Root } from '@radix-ui/react-avatar'
import { cva } from 'class-variance-authority'
import { UserItem } from '../types'
import { cn } from '../utils'

export type UserAvatarProps = {
  user: Pick<UserItem, 'id' | 'name' | 'avatarUrl'>
  size?: 'xs' | 'sm' | 'md'
  fallbackDelay?: number
}

const userAvatarVariants = cva('inline-flex items-center justify-center overflow-hidden', {
  variants: {
    size: {
      xs: 'w-4 h-4',
      sm: 'w-6 h-6',
      md: 'w-8 h-8',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export const UserAvatar = ({ user, size = 'md', fallbackDelay = 600 }: UserAvatarProps) => {
  return (
    <Root className={cn(userAvatarVariants({ size }), 'flex-shrink-0')}>
      <Image src={user.avatarUrl} className='fit-cover h-full w-full rounded-full' alt={user.name} />
      <Fallback
        className='bg-border text-foreground flex h-full w-full items-center justify-center rounded-full font-medium uppercase'
        delayMs={fallbackDelay}
      >
        {user.name?.charAt(0)}
      </Fallback>
    </Root>
  )
}
