import { AnimatePresence, motion } from 'motion/react'
import { PropsWithChildren } from 'react'
import { TableType } from '../types'
import { cn } from '../utils'
import { Flexbox } from './layout'
import { Separator } from './Separator'
import { Text } from './typography'

type Props = PropsWithChildren<{
  table: TableType<any>
}>

export const DataTableToolbar = ({ table, children }: Props) => {
  const selectedRows = table.getSelectedRowModel().rows

  return (
    <AnimatePresence>
      {selectedRows.length > 0 && (
        <Flexbox
          gap={2}
          align='center'
          className={cn(
            'bg-background-2 border-border fixed bottom-4 left-1/2 flex -translate-x-1/2 rounded-lg border shadow-md',
            children ? 'py-1 pl-4 pr-1' : 'px-2 py-1',
          )}
          asChild
        >
          <motion.div layout initial={{ translateY: 100 }} animate={{ translateY: 0 }} exit={{ translateY: 100 }}>
            <Text size='xs'>{selectedRows.length} selected</Text>
            {children && (
              <>
                <Separator orientation='vertical' className='h-4' />
                <Flexbox gap={1}>{children}</Flexbox>
              </>
            )}
          </motion.div>
        </Flexbox>
      )}
    </AnimatePresence>
  )
}
