import {
  Activity,
  AddressBook,
  Airplane,
  AirplaneTilt,
  Alarm,
  Anchor,
  Armchair,
  ArrowDown,
  ArrowElbowDownLeft,
  ArrowLineLeft,
  ArrowLineRight,
  ArrowRight,
  ArrowUp,
  ArrowsClockwise,
  ArrowsDownUp,
  ArrowsLeftRight,
  Axe,
  BabyCarriage,
  Backpack,
  Bag,
  BagSimple,
  Balloon,
  Bank,
  Barcode,
  Barn,
  BaseballCap,
  Basket,
  Basketball,
  Bathtub,
  Beanie,
  BeerBottle,
  Binoculars,
  Boot,
  BowlSteam,
  Brandy,
  Bread,
  Bridge,
  Briefcase,
  BriefcaseMetal,
  Broom,
  Building,
  BuildingApartment,
  BuildingOffice,
  Buildings,
  Cake,
  Calculator,
  CallBell,
  Camera,
  Cardholder,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  CashRegister,
  Chair,
  ChartBar,
  ChartLineUp,
  Chat,
  CheckCircle,
  CheckSquare,
  CheckSquareOffset,
  Checkerboard,
  ChefHat,
  Cherries,
  CircleDashed,
  CircleWavyQuestion,
  City,
  ClipboardText,
  Clock,
  CloudArrowUp,
  CodesandboxLogo,
  Coffee,
  Coin,
  Command,
  Compass,
  Confetti,
  Couch,
  Cpu,
  CreditCard,
  Crown,
  Cube,
  CubeTransparent,
  CurrencyDollar,
  Database,
  Trash as Delete,
  Dot,
  DotsThree,
  DotsThreeVertical,
  Dresser,
  Pencil as Edit,
  Envelope,
  EnvelopeOpen,
  EnvelopeSimpleOpen,
  Exam,
  ArrowSquareOut as ExternalLink,
  FacebookLogo,
  File,
  FileDashed,
  FilePlus,
  FileText,
  Files,
  Fish,
  Flag,
  Flashlight,
  Flask,
  FlyingSaucer,
  Football,
  Funnel,
  GameController,
  Garage,
  Gavel,
  Gear,
  Gift,
  GitFork,
  Globe,
  GlobeSimple,
  GoogleDriveLogo,
  GraduationCap,
  Grains,
  GridFour,
  Guitar,
  HairDryer,
  Hammer,
  Handbag,
  Handshake,
  HardHat,
  Hash,
  Hourglass,
  House,
  IceCream,
  IconProps,
  Images,
  Mailbox as Inbox,
  Infinity,
  Info,
  InstagramLogo,
  Plug as Integrations,
  JarLabel,
  Kanban,
  Key,
  KeyReturn,
  Lectern,
  Lifebuoy,
  Lightning,
  Link,
  LinkedinLogo,
  ListChecks,
  ListPlus,
  Lock,
  MagicWand,
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  MapTrifold,
  Martini,
  Medal,
  MedalMilitary,
  MegaphoneSimple,
  OfficeChair,
  OrangeSlice,
  Package,
  PaintBrushBroad,
  PaintBrushHousehold,
  Palette,
  PaperPlaneRight,
  Paperclip,
  Parachute,
  PauseCircle,
  PhoneCall,
  PiggyBank,
  PixLogo,
  Plug,
  PlugsConnected,
  Popcorn,
  PottedPlant,
  Presentation,
  PresentationChart,
  Printer,
  SquaresFour as Programs,
  Prohibit,
  ProjectorScreenChart,
  PushPin,
  QuestionMark,
  Ranking,
  Robot,
  Scales,
  MagnifyingGlass as Search,
  ShirtFolded,
  ShoppingBagOpen,
  ShoppingCart,
  Shuffle,
  SlackLogo,
  Sneaker,
  SneakerMove,
  SoccerBall,
  Sparkle,
  SpinnerBall,
  Stack,
  Star,
  StarAndCrescent,
  SteamLogo,
  Stop,
  Student,
  Subtitles,
  TShirt,
  Tag,
  Target,
  Taxi,
  TeaBag,
  TennisBall,
  TextT,
  TiktokLogo,
  Timer,
  ToggleRight,
  Toolbox,
  Tote,
  TrafficCone,
  Tray,
  TreeStructure,
  TreeView,
  Trophy,
  Umbrella,
  User,
  UserGear,
  Users,
  VideoConference,
  Volleyball,
  Wallet,
  Warning,
  WarningDiamond,
  GitMerge as Workflows,
  Wrench,
  X,
  XCircle,
  XLogo,
} from '@phosphor-icons/react'
import { VariantProps, cva } from 'class-variance-authority'
import { FC, lazy } from 'react'
import InProgress from '../icons/partners/in-progress.svg'
import Resolved from '../icons/partners/resolved.svg'
import { cn } from '../utils'
import { Flexbox } from './layout'

const NarrativeLogo = lazy(() => import('../icons/narrative-logo.svg'))

const icons = {
  Activity,
  AddressBook,
  Airplane,
  AirplaneTilt,
  Alarm,
  Anchor,
  Armchair,
  ArrowElbowDownLeft,
  ArrowUp,
  ArrowDown,
  ArrowsClockwise,
  ArrowsDownUp,
  ArrowsLeftRight,
  ArrowLineLeft,
  ArrowLineRight,
  Axe,
  BabyCarriage,
  Backpack,
  Bag,
  BagSimple,
  Balloon,
  Bank,
  Barcode,
  Barn,
  BaseballCap,
  Basket,
  Basketball,
  Bathtub,
  Beanie,
  BeerBottle,
  Binoculars,
  Boot,
  BowlSteam,
  Brandy,
  Bread,
  Bridge,
  BriefcaseMetal,
  Broom,
  Building,
  BuildingApartment,
  BuildingOffice,
  Buildings,
  Cake,
  Calculator,
  CallBell,
  Camera,
  Cardholder,
  CaretUp,
  CaretDown,
  CaretLeft,
  CaretRight,
  CashRegister,
  Chair,
  ChartBar,
  ChartLineUp,
  CheckCircle,
  Checkerboard,
  CheckSquare,
  CheckSquareOffset,
  ChefHat,
  Cherries,
  CircleDashed,
  CircleWavyQuestion,
  City,
  ClipboardText,
  Clock,
  CloudArrowUp,
  CodesandboxLogo,
  Coffee,
  Coin,
  Compass,
  Confetti,
  Couch,
  CreditCard,
  Crown,
  Cube,
  CubeTransparent,
  Cpu,
  Database,
  Delete,
  Dot,
  DotsThree,
  DotsThreeVertical,
  Dresser,
  Edit,
  Envelope,
  EnvelopeOpen,
  EnvelopeSimpleOpen,
  Exam,
  ExternalLink,
  FacebookLogo,
  File,
  FileDashed,
  FileText,
  Files,
  Fish,
  Flashlight,
  Flask,
  FlyingSaucer,
  Football,
  GameController,
  Garage,
  Gavel,
  Gear,
  Gift,
  GitFork,
  Globe,
  GlobeSimple,
  GoogleDriveLogo,
  GraduationCap,
  Grains,
  GridFour,
  Guitar,
  HairDryer,
  Hammer,
  Handbag,
  Handshake,
  HardHat,
  Hash,
  Hourglass,
  House,
  IceCream,
  Images,
  Inbox,
  Info,
  Infinity,
  InProgress,
  InstagramLogo,
  Integrations,
  JarLabel,
  Kanban,
  Lectern,
  Lifebuoy,
  Lightning,
  LinkedinLogo,
  ListChecks,
  ListPlus,
  MagicWand,
  MapTrifold,
  Martini,
  Medal,
  MedalMilitary,
  MegaphoneSimple,
  NarrativeLogo,
  OfficeChair,
  OrangeSlice,
  Package,
  PaintBrushBroad,
  PaintBrushHousehold,
  Palette,
  PaperPlaneRight,
  Paperclip,
  Parachute,
  PauseCircle,
  PiggyBank,
  PixLogo,
  PhoneCall,
  Plug,
  PlugsConnected,
  Popcorn,
  PottedPlant,
  Presentation,
  PresentationChart,
  Printer,
  Programs,
  ProjectorScreenChart,
  PushPin,
  QuestionMark,
  Ranking,
  Resolved,
  Robot,
  Scales,
  Search,
  ShirtFolded,
  Shuffle,
  ShoppingBagOpen,
  ShoppingCart,
  SlackLogo,
  Sneaker,
  SneakerMove,
  SoccerBall,
  SpinnerBall,
  Sparkle,
  Stack,
  Star,
  StarAndCrescent,
  SteamLogo,
  Student,
  Tag,
  Target,
  Taxi,
  TextT,
  TeaBag,
  TennisBall,
  ToggleRight,
  Toolbox,
  Tote,
  TrafficCone,
  Tray,
  TreeStructure,
  TreeView,
  Trophy,
  TShirt,
  Umbrella,
  VideoConference,
  Volleyball,
  Wallet,
  Workflows,
  Wrench,
  X,
  XLogo,
  FilePlus,
  Funnel,
  User,
  ArrowRight,
  CurrencyDollar,
  Users,
  UserGear,
  TiktokLogo,
  WarningDiamond,
  XCircle,
  Prohibit,
  Lock,
  Command,
  KeyReturn,
  Subtitles,
  Timer,
  Stop,
  Key,
  Flag,
  Briefcase,
  Link,
  Warning,
  MagnifyingGlassPlus,
  MagnifyingGlassMinus,
  Chat,
}

const variants = cva(undefined, {
  variants: {
    color: {
      amber: 'bg-amber-300 text-amber-1100',
      blue: 'bg-blue-300 text-blue-1100',
      bronze: 'bg-bronze-300 text-bronze-1100',
      cyan: 'bg-cyan-300 text-cyan-1100',
      gold: 'bg-gold-300 text-gold-1100',
      green: 'bg-green-300 text-green-1100',
      indigo: 'bg-indigo-300 text-indigo-1100',
      mauve: 'bg-mauve-300 text-mauve-1100',
      mint: 'bg-mint-300 text-mint-1100',
      olive: 'bg-olive-300 text-olive-1100',
      orange: 'bg-orange-300 text-orange-1100',
      pink: 'bg-pink-300 text-pink-1100',
      plum: 'bg-plum-300 text-plum-1100',
      purple: 'bg-purple-300 text-purple-1100',
      red: 'bg-red-300 text-red-1100',
      sky: 'bg-sky-300 text-sky-1100',
      violet: 'bg-violet-300 text-violet-1100',
      yellow: 'bg-yellow-300 text-yellow-1100',
      gray: 'bg-gray-300 text-gray-1100',
    },
  },
  compoundVariants: [
    {
      color: [
        'amber',
        'blue',
        'bronze',
        'cyan',
        'gold',
        'green',
        'indigo',
        'mauve',
        'mint',
        'olive',
        'orange',
        'pink',
        'plum',
        'purple',
        'red',
        'sky',
        'violet',
        'yellow',
      ],
      className: 'p-1 rounded-md',
    },
  ],
})

export type PhosphorIconName = keyof typeof icons
export const PhosphorIconNames = Object.keys(icons)

export type PhosphorIconProps = {
  name: PhosphorIconName
} & IconProps &
  VariantProps<typeof variants>

export const PhosphorIcon: FC<PhosphorIconProps> = ({
  name,
  size,
  color,
  className,
  width = '1em',
  height = '1em',
  ...rest
}) => {
  const Icon = icons[name] || QuestionMark

  if (color) {
    return (
      <Flexbox className={cn(variants({ color }), className)}>
        <Icon size={size} width={width} height={height} {...rest} />
      </Flexbox>
    )
  }

  return <Icon className={className} size={size} width={width} height={height} {...rest} />
}
